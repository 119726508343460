import React, { useState, useEffect } from "react";
import "../styles/style.sass";
import Cookies, { get } from "js-cookie";
import DonationBanner from "./donation-banner";
import CookieBanner from "./cookie-consent";

import { useStaticQuery, graphql } from "gatsby";
import {
  ArrowRightOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const currentLanguage = "en";

const categoriesArray =
  currentLanguage === "it"
    ? ["Arte", "Letteratura", "Musica", "Storia"]
    : ["Art", "History", "Literature", "Music"];

const MenuTop = () => {
  const data = useStaticQuery(graphql`
    query ArticlesSubMenu {
      Art: allEnYaml(
        limit: 4
        filter: { category: { eq: "Art" } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          cover {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          h1
          description
          coveralt
          link
        }
      }

      Music: allEnYaml(
        limit: 4
        filter: { category: { eq: "Music" } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          cover {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          h1
          description
          coveralt
          link
        }
      }
      Literature: allEnYaml(
        limit: 4
        filter: { category: { eq: "Literature" } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          cover {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          h1
          description
          coveralt
          link
        }
      }
      History: allEnYaml(
        limit: 4
        filter: { category: { eq: "History" } }
        sort: { fields: date, order: DESC }
      ) {
        nodes {
          cover {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          h1
          description
          coveralt
          link
        }
      }
    }
  `);
  const [drawer, setDrawer] = useState(false);
  const [categoriesBanner, setCategoriesBanner] = useState("");
  const [donationBanner, setDonationBanner] = useState(false);
  const [cookieBanner, setCookieBanner] = useState(false);

  function SubMenuArticle({
    number,
    title,
    cover,
    coveralt,
    description,
    link,
    category,
  }) {
    return (
      <div type={number} className="article-card-medium">
        <a href={link}>
          <GatsbyImage
            onMouseEnter={() => setCategoriesBanner(category)}
            image={getImage(cover)}
            alt={coveralt}
          />
          <div className="article-card-text">
            <span>{title}</span>
            <p>{description}</p>
          </div>
          <button>
            Read <ArrowRightOutlined />
          </button>
        </a>
      </div>
    );
  }

  function SubMenu({ category }) {
    return (
      <div
        onMouseLeave={() => setCategoriesBanner(false)}
        onMouseEnter={() => setCategoriesBanner(category)}
        className="subMenu"
      >
        <div className="subMenu-title">
          <div category={category} className="categoryPoint" />
          <a
            href={
              "https://extra.ilsalice.org/" + category.toLowerCase() + ".php"
            }
          >
            {category + " "}
          </a>
          <span
            style={{
              fontSize: 14,
              fontWeight: 400,
              fontFamily: "Helvetica Neue, sans-serif",
            }}
          >
            / latest articles
          </span>
        </div>
        {categoriesBanner && (
          <div className="subMenu-articles-w">
            <SubMenuArticle
              category={category}
              link={data[categoriesBanner].nodes[0].link}
              cover={data[categoriesBanner].nodes[0].cover}
              coveralt={data[categoriesBanner].nodes[0].coveralt}
              title={data[categoriesBanner].nodes[0].h1}
              description={data[categoriesBanner].nodes[0].description}
            ></SubMenuArticle>
            <SubMenuArticle
              category={category}
              link={data[categoriesBanner].nodes[1].link}
              cover={data[categoriesBanner].nodes[1].cover}
              coveralt={data[categoriesBanner].nodes[1].coveralt}
              title={data[categoriesBanner].nodes[1].h1}
              description={data[categoriesBanner].nodes[1].description}
              number={"second"}
            ></SubMenuArticle>
            <SubMenuArticle
              category={category}
              link={data[categoriesBanner].nodes[2].link}
              cover={data[categoriesBanner].nodes[2].cover}
              coveralt={data[categoriesBanner].nodes[2].coveralt}
              title={data[categoriesBanner].nodes[2].h1}
              description={data[categoriesBanner].nodes[2].description}
              number={"penultumate"}
            ></SubMenuArticle>
            <SubMenuArticle
              category={category}
              link={data[categoriesBanner].nodes[3].link}
              cover={data[categoriesBanner].nodes[3].cover}
              coveralt={data[categoriesBanner].nodes[3].coveralt}
              title={data[categoriesBanner].nodes[3].h1}
              description={data[categoriesBanner].nodes[3].description}
              number={"last"}
            ></SubMenuArticle>
          </div>
        )}

        <button style={{ fontWeight: 600 }}>
          <a
            href={
              "https://extra.ilsalice.org/" + category.toLowerCase() + ".php"
            }
          >
            All articles <ArrowRightOutlined />
          </a>
        </button>
      </div>
    );
  }

  const close = () => {
    setDonationBanner(false);
    Cookies.set("donation", "seen");
  };

  useEffect(() => {
    if (!Cookies.get("donation", "seen")) {
      setDonationBanner(true);
    }
    if (Cookies.get("ga-cookie-consent") !== true) {
      setCookieBanner(true);
    }
  }, []);

  return (
    <>
      <div className="header-w">
        <div className="header">
          <a href="https://extra.ilsalice.org">
            <div className="logo">
              <img
                width="28"
                height="28"
                alt="ilSalice EXTRA logo"
                src="/icon.jpg"
              />
              IlSalice EXTRA
            </div>
          </a>

          {donationBanner && (
            <DonationBanner close={close} language="en"></DonationBanner>
          )}

          <div className="menu">
            {categoriesArray.map((item) => {
              return (
                <button
                  onMouseLeave={() => setCategoriesBanner(false)}
                  onMouseEnter={() => setCategoriesBanner(item)}
                >
                  <a
                    href={
                      "https://extra.ilsalice.org/" +
                      item.toLowerCase() +
                      ".php"
                    }
                  >
                    {item}
                  </a>
                </button>
              );
            })}
            <button
              onClick={() => {
                Cookies.remove("donation");
                setTimeout(() => {
                  setDonationBanner(true);
                }, 100);
              }}
              id="btn-menu-support"
            >
              <a>Support</a>&nbsp;
              <HeartOutlined />
            </button>
            <button className="btn-round-black">
              <a href="https://ilsalice.org/login">Login</a>
            </button>
          </div>
          {drawer ? (
            <button
              onClick={() => setDrawer(false)}
              className="menu-mobule-icon"
            >
              <CloseOutlined style={{ fontSize: 20 }} />
            </button>
          ) : (
            <button
              onClick={() => setDrawer(true)}
              className="menu-mobule-icon"
            >
              <MenuFoldOutlined style={{ fontSize: 20 }} />
            </button>
          )}
        </div>
        {cookieBanner && <CookieBanner language={"en"}></CookieBanner>}
      </div>
      {drawer && (
        <div className="menu-drawer">
          <div>
            <h3>
              <a href="https://extra.ilsalice.org/art.php">
                Art <span category={"Art"}>•</span>
              </a>
            </h3>
            <h3>
              <a href="https://extra.ilsalice.org/history.php">
                History <span category={"History"}>•</span>
              </a>
            </h3>
            <h3>
              <a href="https://extra.ilsalice.org/literature.php">
                Literature <span category={"Literature"}>•</span>
              </a>
            </h3>
            <h3>
              <a href="https://extra.ilsalice.org/music.php">
                Music <span category={"Music"}>•</span>
              </a>
            </h3>
          </div>

          <button className="btn-round-black">
            <a href="https://ilsalice.org/login">Login</a>
          </button>
        </div>
      )}

      {categoriesBanner && <SubMenu category={categoriesBanner}></SubMenu>}
    </>
  );
};

export default MenuTop;
