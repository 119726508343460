import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function IndexCover({ data }) {
  if (data[0].version === 0) {
    return (
      <a href={data[0].link}>
        <div className="cover-margin">
          <div id="cover-w">
            <div id="cover-text-w">
              <div className="cover-toparticle">Top Article</div>
              <h2 className="cover-title">{data[0].h1}</h2>
              <p className="cover-p">{data[0].description}</p>
            </div>
            <GatsbyImage
              alt={data[0].coveralt}
              imgClassName="cover-article-img"
              image={getImage(data[0].cover)}
              loading="eager"
            />
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <Link to={data[0].link}>
        <div className="cover-margin">
          <div id="cover-w">
            <div id="cover-text-w">
              <div className="cover-toparticle">Top Article</div>
              <h2 className="cover-title">{data[0].h1}</h2>
              <p className="cover-p">{data[0].description}</p>
            </div>
            <GatsbyImage
              alt={data[0].coveralt}
              image={getImage(data[0].cover)}
            />
          </div>
        </div>
      </Link>
    );
  }
}
