import * as React from "react";
import { graphql } from "gatsby";
import { ArrowRightOutlined } from "@ant-design/icons";
import ArticleCard from "./article-card";

export default function IndexCover({ header, articlesList, a, language }) {
  function filter(array, value, key) {
    return array.filter(
      key
        ? (a) => a[key] === value
        : (a) => Object.keys(a).some((k) => a[k] === value)
    );
  }

  const column1 = articlesList[0].column;
  const column2 = articlesList[1].column;
  const column3 = articlesList[2].column;

  var column1Array = [];
  var column2Array = [];
  var column3Array = [];

  var column1TypeArray = [];
  var column2TypeArray = [];
  var column3TypeArray = [];

  column1.map((item) => {
    column1Array.push(item.link);
    column1TypeArray.push(item.type);
  });

  column2.map((item) => {
    column2Array.push(item.link);
    column2TypeArray.push(item.type);
  });
  column3.map((item) => {
    column3Array.push(item.link);
    column3TypeArray.push(item.type);
  });

  var column1ArticlesArray = [];
  var column2ArticlesArray = [];
  var column3ArticlesArray = [];

  column1Array.forEach((element) => {
    column1ArticlesArray.push(filter(a, element));
  });
  column1ArticlesArray.map(
    (item, key) => (item[0].type = column1TypeArray[key])
  );

  column2Array.forEach((element) => {
    column2ArticlesArray.push(filter(a, element));
  });
  column2ArticlesArray.map(
    (item, key) => (item[0].type = column2TypeArray[key])
  );

  column3ArticlesArray.map(
    (item, key) => (item[0].type = column3TypeArray[key])
  );
  column3Array.forEach((element) => {
    column3ArticlesArray.push(filter(a, element));
  });

  return (
    <section className="category-section">
      <div className="section-divider"></div>
      <div className="section-title">
        {language == "it" ? (
          <a
            href={
              "https://extra.ilsalice.org/it/" + header.toLowerCase() + ".php"
            }
          >
            {header} <span> / </span> <span className="arrow">Vedi tutti</span>
            <ArrowRightOutlined style={{ fontSize: 17 }} />
          </a>
        ) : (
          <a
            href={"https://extra.ilsalice.org/" + header.toLowerCase() + ".php"}
          >
            {header} <span> / </span> <span className="arrow">See All</span>
            <ArrowRightOutlined style={{ fontSize: 17 }} />
          </a>
        )}
      </div>
      <div className="columns-w">
        <div className="column">
          {column1ArticlesArray.map((item) => {
            return (
              <ArticleCard
                type={item[0].type}
                category={item[0].category}
                title={item[0].h1}
                img={item[0].cover}
                alt={item[0].coveralt}
                link={item[0].link}
                author={item[0].author}
                date={item[0].date}
                description={item[0].description}
                language={item[0].language}
              />
            );
          })}
        </div>
        <div className="column-small">
          {column2ArticlesArray.map((item) => {
            return (
              <ArticleCard
                type={item[0].type}
                category={item[0].category}
                title={item[0].h1}
                img={item[0].cover}
                alt={item[0].coveralt}
                link={item[0].link}
                author={item[0].author}
                date={item[0].date}
                description={item[0].description}
                language={item[0].language}
              />
            );
          })}
        </div>
        <div className="column-small">
          {column3ArticlesArray.map((item) => {
            return (
              <ArticleCard
                type={item[0].type}
                category={item[0].category}
                title={item[0].h1}
                img={item[0].cover}
                alt={item[0].coveralt}
                link={item[0].link}
                author={item[0].author}
                date={item[0].date}
                description={item[0].description}
                language={item[0].language}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
