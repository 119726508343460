import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import En from "../../translations/en.yaml";
import It from "../../translations/it.yaml";

export default function ArticleCard({
  img,
  evidenza,
  title,
  category,
  author,
  date,
  description,
  link,
  version,
  type,
  alt,
  language,
}) {
  var lang = language == "en" ? En : language == "it" ? It : En;
  return (
    <a href={link}>
      <div className="article-card">
        {type != "noimage" && (
          <div style={{ overflow: "hidden", maxWidth: "100%" }}>
            <GatsbyImage alt={alt} image={getImage(img)} />
          </div>
        )}

        {evidenza == true ? (
          <div className="article-card-text-black-w">
            <div className="article-card-author">
              <span>most popular</span>
            </div>
            <div className="article-card-title">{title}</div>
            <div className="article-card-author">
              {author} • {date}
            </div>
            <p>{description}</p>
          </div>
        ) : (
          <div className="article-card-text-w">
            {type == "noimage" && (
              <div style={{ marginBottom: 30 }} className="divider" />
            )}
            <div className="article-card-category" category={category}>
              {lang[category]}
            </div>
            <div className="article-card-title">{title}</div>
            <div className="article-card-author">
              {language === "it" ? "Di" : "By"} {author} • {date}
            </div>
            <p>{description}</p>
            {type == "noimage" && (
              <div style={{ marginTop: 30 }} className="divider" />
            )}
          </div>
        )}
      </div>
    </a>
  );
}
