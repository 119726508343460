import { graphql } from "gatsby";
import * as React from "react";
import ArticleData from "../content/content.yaml";
import Menu from "../components/menu";
import CoverComponent from "../components/index/cover";
import LatestsSection from "../components/index/latests";
import ArticlesSection from "../components/index/articles-section";
import FullWidthArticle from "../components/index/full-width-article";
import Structure from "../structures/index-structure-EN.yaml";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const IndexPage = ({ data }) => {
  var a = data.allEnYaml.nodes;

  function filter(array, value, key) {
    return array.filter(
      key
        ? (a) => a[key] === value
        : (a) => Object.keys(a).some((k) => a[k] === value)
    );
  }

  const schemaMarkup = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "IlSalice EXTRA",
    url: "https://extra.ilsalice.org/",
    potentialAction: {
      "@type": "SearchAction",
      target: "{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <main>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>IlSalice EXTRA | Art and culture blog</title>
        <meta
          name="description"
          content="Articles and graphics on history, music, art, literature and culture in general."
        ></meta>
        <link rel="canonical" href="https://extra.ilsalice.org/"></link>
        <link
          rel="alternate"
          href="https://extra.ilsalice.org/it/"
          hreflang="it"
        ></link>
        <link
          rel="alternate"
          href="https://extra.ilsalice.org/"
          hreflang="en"
        ></link>
        <meta property="og:title" content="IlSalice EXTRA" />
        <meta
          property="og:description"
          content="Articles and graphics on history, music, art, literature and culture in general."
        />
        <meta
          property="og:image"
          content="https://extra.ilsalice.org/icons/ilsaliceEX.png"
        />
        <meta property="og:url" content="https://extra.ilsalice.org/" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <Menu data={data} />

      <div className="main-div">
        <CoverComponent data={filter(a, Structure.coverarticle[0].link)} />
        <LatestsSection language="en" />

        {Structure.contents.map((item) => {
          if (item.section) {
            return (
              <ArticlesSection
                header={item.section.header}
                a={a}
                articlesList={item.section.content}
              />
            );
          } else if (item.fullwidthArticle) {
            return <FullWidthArticle link={item.fullwidthArticle.link} a={a} />;
          }
        })}
      </div>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query AllArticles {
    allEnYaml {
      nodes {
        category
        author
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 1500
              quality: 50
              breakpoints: [400, 750, 1080, 1366, 1920]
              placeholder: NONE
              layout: CONSTRAINED
            )
          }
        }
        coveralt
        description
        date(formatString: "DD MMMM YYYY")
        h1
        link
        version
      }
    }
  }
`;
