import { Link } from "gatsby";
import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function ({ link, a }) {
  function filter(array, value, key) {
    return array.filter(
      key
        ? (a) => a[key] === value
        : (a) => Object.keys(a).some((k) => a[k] === value)
    );
  }

  const article = filter(a, link);

  if (article[0].version === 0) {
    return (
      <a href={article[0].link}>
        <div className="full-width-article">
          <GatsbyImage
            alt={article[0].coveralt}
            image={getImage(article[0].cover)}
          />
          <div className="dark-filter">
            <h2>{article[0].h1}</h2>
            <div className="divider"></div>
            <p>{article[0].description}</p>
          </div>
        </div>
      </a>
    );
  } else {
    return (
      <Link to={article[0].link}>
        <div className="full-width-article">
          <img src={article[0].cover} />
          <GatsbyImage
            alt={article[0].coveralt}
            image={getImage(article[0].cover)}
          />
          <div className="dark-filter">
            <h2>{article[0].h1}</h2>
            <div className="divider"></div>
            <p>{article[0].description}</p>
          </div>
        </div>
      </Link>
    );
  }
}
